import * as React from 'react'
import { IHeader } from './Header/Header'
import { IPage } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import css from './Layout.scss'
import { useEffect, useState } from 'react'
import { getStoreCode } from '../../Utils/getStoreCodeFromCookie'

export interface ILayoutProps {
  // TODO: create proper interfaces
  header: IHeader[]
  footer: IPage[]
}

interface IVideoSetterFunction {
  (value: React.SetStateAction<IVideo | null>): void
  (arg0: any): void
}

interface IGeolocationTags {
  id: string
  value: string
  include: boolean
}

interface IVideo {
  videoUrl: string
  ariaLabel?: string
  targetUrl?: string
  videoVisibility?: string
  parentVisibility?: string
  parentGeolocation?: IGeolocationTags[]
}

const visibilityEnum = {
  VISIBLE_ALL: 'Visible All',
  DESKTOP_ONLY: 'Desktop Only',
  DESKTOP_TABLE_ONLY: 'Desktop & Tablet Only',
  TABLE_ONLY: 'Tablet Only',
  TABLE_MOBILE_ONLY: 'Tablet & Mobile Only',
  MOBILE_ONLY: 'Mobile Only',
  HIDDEN: 'HIDDEN',
}

const checkVisibility = (innerWidth: number, visibility: string) => {
  switch (visibility) {
    case visibilityEnum.VISIBLE_ALL:
      return true
    case visibilityEnum.DESKTOP_ONLY:
      return innerWidth >= 1024
    case visibilityEnum.DESKTOP_TABLE_ONLY:
      return innerWidth >= 768
    case visibilityEnum.TABLE_ONLY:
      return innerWidth >= 768 && innerWidth < 1024
    case visibilityEnum.TABLE_MOBILE_ONLY:
      return innerWidth < 1024
    case visibilityEnum.MOBILE_ONLY:
      return innerWidth < 768
    case visibilityEnum.HIDDEN:
      return false
    default:
      return false // Default to hidden if no valid visibility is provided
  }
}

const checkGeolocationVisibility = (block: any) => {
  const storeCodes = block?.geotaggingOptions?.groups?.find(group => group?.type === 'code')?.tags

  if (storeCodes.length > 0) {
    const codes = storeCodes.map(storeCode => storeCode.value)
    return codes.includes(getStoreCode())
  }

  return true
}

const findAndRemoveVideoElements = (
  obj: { [s: string]: any } | ArrayLike<unknown>,
  setVideo: IVideoSetterFunction,
  screenWidth: number,
) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value.children && Array.isArray(value.children)) {
      value.children = value.children.filter(element => {
        const hasVideoChild = element.children?.some(child => child.type === 'VIDEO')

        if (hasVideoChild) {
          element.children = element.children.filter(child => {
            if (child.type === 'VIDEO') {
              if (
                child?.videoUrl &&
                child?.visibilityBreakpoint?.label &&
                checkVisibility(screenWidth, child?.visibilityBreakpoint?.label) &&
                checkGeolocationVisibility(value)
              ) {
                const video: IVideo = {
                  videoUrl: child.videoUrl,
                  ariaLabel: child.alt,
                  targetUrl: child.targetUrl,
                }
                setVideo(video)
              }
              return false // Remove the video element
            }
            return true
          })
        }

        return true // Keep non-video elements
      })
    }
  }
}

/**
 * The core wrapper for all pages
 */
const Layout = (props: any) => {
  const miniheaders: string[] = ['/checkout']
  const homeHeader: string[] = ['/']
  const hasMargin: boolean = miniheaders.indexOf(props.url) === -1 ? true : false
  const noMarginTop: boolean = homeHeader.indexOf(props.url) !== -1 ? true : false
  const [renderVideo, setRenderVideo] = useState(false)
  const [video, setVideo] = useState<IVideo | null>(null)
  const [redirecting, setRedirecting] = useState<boolean>(false)

  useEffect(() => {
    if (window.location.pathname === '/') {
      props?.children?.props?.data?.snapshot?.lookup &&
        findAndRemoveVideoElements(props.children.props.data.snapshot.lookup, setVideo, window.innerWidth)
      setRenderVideo(true)
    }
  }, [])

  const handleClick = () => {
    if (video?.targetUrl) {
      setRedirecting(true)
      window.location.href = video?.targetUrl
    }
  }

  return (
    <React.Fragment>
      <div className={`${hasMargin ? (noMarginTop ? '' : css.layout) : css.checkoutHeader} cor-app margin-bottom`}>
        {renderVideo && video?.videoUrl && (
          <video
            aria-label={video.ariaLabel}
            width="100%"
            height="100%"
            controls={false}
            autoPlay
            loop
            muted
            playsInline
            onClick={handleClick}
            style={{ cursor: redirecting ? 'progress' : 'pointer' }}
          >
            <source src={video.videoUrl} type="video/mp4" />
          </video>
        )}
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default Layout
